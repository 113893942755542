<template>
  <div>
    <div v-if="loading">
      <loading-state />
    </div>

    <div v-else>

      <columns class="is-mobile is-desktop is-multiline">
        <!-- <column class="is-half-mobile is-one-fifth-desktop">
          <box class="has-text-centered is-shadowless  ">
            <level heading="team" :title="totalPlayers"></level>
          </box>
        </column> -->

        <column class="is-half-mobile is-one-fifth-desktop">
          <box class="has-text-centered is-shadowless ">
            <level heading="total time" :title="$filters.convertDuration(timetotal)"></level>
          </box>
        </column>

        <column class="is-half-mobile is-one-fifth-desktop">
          <box class="has-text-centered is-shadowless ">
            <level heading="complete" :title="completed.toString() + '%'"></level>
          </box>
        </column>

        <column class="is-half-mobile is-one-fifth-desktop">
          <box class="has-text-centered is-shadowless ">
            <level heading="open issues" :title="opened"></level>
          </box>
        </column>

        <column class="is-half-mobile is-one-fifth-desktop">
          <box class="has-text-centered is-shadowless ">
             <level heading="closed issues" :title="closed"></level>
          </box>
        </column>

        <column class="is-half-mobile is-one-fifth-desktop">
          <box class="has-text-centered is-shadowless ">
             <level heading="all issues" :title="all"></level>
          </box>
        </column>

      </columns>


      <columns class="mt-5 mb-6 is-multiline">

        <column class="">
          <box class="has-text-centered is-shadowless ">
            <h4 class="heading my-3">Timespent</h4>
            <donut-time-spent :data="issuesTimeByMembers"/>
          </box>
        </column>

         <column class="">
          <box class="has-text-centered is-shadowless ">
            <h4 class="heading my-3">Issues</h4>
            <donut-issues :data="issuesCountByMembers" />
          </box>
        </column>

        <column class="is-two-fifths-widescreen">
          <box class="has-text-centered is-shadowless ">
            <h4 class="heading my-3">Issues State</h4>
            <bar-stack-issue :data="issuesStateByMembers"  />
          </box>
        </column>
      </columns>


      <columns class="mt-5 mb-6 is-multiline">
        <column>

            
        </column>
      </columns>
    </div>
  </div>
</template>

<script>
import { computed, defineAsyncComponent } from 'vue'
import { getProjectStatsApi } from '@/use/useProject'

export default {
  props: {
    fullPath: {
      type: String,
      required: true
    },
  },

  components: {
    LoadingState: defineAsyncComponent(() => import('@/components/general/Loading')),
    Columns: defineAsyncComponent(() => import('@/components/ds/Columns.vue')),
    Column: defineAsyncComponent(() => import('@/components/ds/Column.vue')),
    Box: defineAsyncComponent(() => import('@/components/ds/Box.vue')),
    Level: defineAsyncComponent(() => import('@/components/ds/Level.vue')),
    DonutIssues: defineAsyncComponent(() => import('@/components/widgets/charts/donuts')),
    DonutTimeSpent: defineAsyncComponent(() => import('@/components/widgets/charts/donutsTimespent')),
    BarStackIssue: defineAsyncComponent(() => import('@/components/widgets/charts/issuesStatus'))
  },

  setup (props) {

    const  {
      loading,
      opened,
      closed,
      all,
      timetotal,
      assignees,
      issuesCountByMembers,
      issuesTimeByMembers,
      issuesStateByMembers
    } = getProjectStatsApi(props.fullPath)

    const completed = computed(() => {
      let _rs = (parseFloat(closed.value) / parseFloat(all.value)).toFixed(2) * 100;
      return isNaN(_rs) ? 0 : _rs.toFixed(0)
    })

    const totalPlayers = computed(() => assignees ? assignees.value.length : 0 )


    return {
      loading,
      opened,
      closed,
      all,
      completed,
      timetotal,
      totalPlayers,
      issuesCountByMembers,
      issuesTimeByMembers,
      issuesStateByMembers
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
